import i18n from '../../translations/i18n';
import {
    EDIT_APPOINTMENT_BOOKING,
    EDIT_CITY, EDIT_COMPANY_DEPARTMENT,
    EDIT_COMPANY_LINKEDIN,
    EDIT_COMPANY_NAME,
    EDIT_COMPANY_POSITION,
    EDIT_COUNTRY,
    EDIT_COVER_IMAGE,
    EDIT_EMAIL,
    EDIT_FACEBOOK,
    EDIT_INSTAGRAM,
    EDIT_PHONE,
    EDIT_PINTEREST, EDIT_POSTAL_CODE, EDIT_PROFILE_ABOUT, EDIT_PROFILE_APPEARANCE,
    EDIT_PROFILE_FULLNAME,
    EDIT_PROFILE_IMAGE, EDIT_REDDIT,
    EDIT_SNAPCHAT, EDIT_STREET_ADDRESS, EDIT_THREADS, EDIT_TIKTOK,
    EDIT_WEBSITE, EDIT_X,
    EDIT_YOUTUBE
} from '../../constants/permission';
import {CompanyPermissionType} from '../../types/company';


export interface Permission {
    name: string;
    value: CompanyPermissionType;
}

interface PermissionsCategory {
    left: Permission[];
    right: Permission[];
}

interface PermissionsData {
    General: PermissionsCategory;
    Links: PermissionsCategory;
    Contact: PermissionsCategory;
    Other: PermissionsCategory;
}

export const getPermissionsData = (): PermissionsData => ({
    General: {
        left: [
            {name: i18n.t('labels.fullname'), value: EDIT_PROFILE_FULLNAME},
            {name: i18n.t('labels.company'), value: EDIT_COMPANY_NAME},
            {name: i18n.t('labels.position'), value: EDIT_COMPANY_POSITION}
        ],
        right: [
            {name: i18n.t('labels.department'), value: EDIT_COMPANY_DEPARTMENT},
            {name: i18n.t('common.profilePicture'), value: EDIT_PROFILE_IMAGE},
            {name: i18n.t('labels.coverImage'), value: EDIT_COVER_IMAGE}
        ]
    },
    Links: {
        left: [
            //TODO separate to personal and company when implemented
            {name: i18n.t('common.linkedin'), value: EDIT_COMPANY_LINKEDIN},
            {name: i18n.t('common.instagram'), value: EDIT_INSTAGRAM},
            {name: i18n.t('common.facebook'), value: EDIT_FACEBOOK},
            {name: i18n.t('common.x'), value: EDIT_X},
            {name: i18n.t('common.tiktok'), value: EDIT_TIKTOK}
        ],
        right: [
            {name: i18n.t('common.youtube'), value: EDIT_YOUTUBE},
            {name: i18n.t('common.snapchat'), value: EDIT_SNAPCHAT},
            {name: i18n.t('common.pinterest'), value: EDIT_PINTEREST},
            {name: i18n.t('common.threads'), value: EDIT_THREADS},
            {name: i18n.t('common.reddit'), value: EDIT_REDDIT}
        ]
    },
    Contact: {
        left: [
            {name: i18n.t('common.phone'), value: EDIT_PHONE},
            {name: i18n.t('common.email'), value: EDIT_EMAIL},
            {name: i18n.t('common.website'), value: EDIT_WEBSITE},
            {name: i18n.t('common.appointmentBooking'), value: EDIT_APPOINTMENT_BOOKING}
        ],
        right: [
            {name: i18n.t('labels.country'), value: EDIT_COUNTRY},
            {name: i18n.t('labels.city'), value: EDIT_CITY},
            {name: i18n.t('labels.streetAdress'), value: EDIT_STREET_ADDRESS},
            {name: i18n.t('labels.zipPostal'), value: EDIT_POSTAL_CODE}
        ]
    },
    Other: {
        left: [
            {name: i18n.t('common.about'), value: EDIT_PROFILE_ABOUT},
            {name: i18n.t('common.appearance'), value: EDIT_PROFILE_APPEARANCE}
            // {name: i18n.t('common.recentActivities'), value: EDIT_RECENT_ACTIVITIES} //TODO enable when implemented
        ],
        right: []
    }
});

export const getAllPermissions = (permissions: PermissionsCategory): CompanyPermissionType[] => {
    return [
        ...permissions.left.map((pl: Permission) => pl.value),
        ...permissions.right.map((pl: Permission) => pl.value)
    ];
};