export const EDIT_PROFILE_FULLNAME = 'EDIT_PROFILE_FULLNAME';
export const EDIT_COMPANY_NAME = 'EDIT_COMPANY_NAME';
export const EDIT_COMPANY_POSITION = 'EDIT_COMPANY_POSITION';
export const EDIT_COMPANY_DEPARTMENT = 'EDIT_COMPANY_DEPARTMENT';
export const EDIT_PROFILE_IMAGE = 'EDIT_PROFILE_IMAGE';
export const EDIT_COVER_IMAGE = 'EDIT_COVER_IMAGE';
export const EDIT_PERSONAL_LINKEDIN = 'EDIT_PERSONAL_LINKEDIN';
export const EDIT_COMPANY_LINKEDIN = 'EDIT_COMPANY_LINKEDIN';
export const EDIT_INSTAGRAM = 'EDIT_INSTAGRAM';
export const EDIT_FACEBOOK = 'EDIT_FACEBOOK';
export const EDIT_YOUTUBE = 'EDIT_YOUTUBE';
export const EDIT_SNAPCHAT = 'EDIT_SNAPCHAT';
export const EDIT_PINTEREST = 'EDIT_PINTEREST';
export const EDIT_X = 'EDIT_X';
export const EDIT_TIKTOK = 'EDIT_TIKTOK';
export const EDIT_THREADS = 'EDIT_THREADS';
export const EDIT_REDDIT = 'EDIT_REDDIT';
export const EDIT_PHONE = 'EDIT_PHONE';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const EDIT_WEBSITE = 'EDIT_WEBSITE';
export const EDIT_APPOINTMENT_BOOKING = 'EDIT_APPOINTMENT_BOOKING';
export const EDIT_COUNTRY = 'EDIT_COUNTRY';
export const EDIT_CITY = 'EDIT_CITY';
export const EDIT_STREET_ADDRESS = 'EDIT_STREET_ADDRESS';
export const EDIT_POSTAL_CODE = 'EDIT_POSTAL_CODE';
export const EDIT_PROFILE_ABOUT = 'EDIT_PROFILE_ABOUT';
export const EDIT_PROFILE_APPEARANCE = 'EDIT_PROFILE_APPEARANCE';
export const EDIT_RECENT_ACTIVITIES = 'EDIT_RECENT_ACTIVITIES';
