import {CardProfileSocialLink, CardProfileSocialLinksUpdate} from '../../types/cardProfile';
import {
    FACEBOOK,
    INSTAGRAM,
    LINKEDIN,
    PINTEREST,
    REDDIT,
    SNAPCHAT,
    SocialLinks,
    THREADS,
    TIKTOK,
    X,
    YOUTUBE
} from '../../types/socialLinks';
import {
    canEditCompanyLinkedin, canEditFacebook, canEditInstagram,
    canEditPinterest, canEditReddit, canEditSnapchat, canEditThreads,
    canEditTikTok, canEditX, canEditYoutube
} from '../../store/utils/company';

export const options = [
    FACEBOOK, LINKEDIN, INSTAGRAM, TIKTOK, X, YOUTUBE, SNAPCHAT, THREADS, PINTEREST, REDDIT
];

export const getMenuItemStyles = (option: string, links:CardProfileSocialLinksUpdate[]) => {
    const isSelected = links.some(link => link.type === option);
    const cursor = isSelected ? 'default' : '';
    
    return {
        fontWeight: isSelected ? 'bold' : 'normal',
        cursor: cursor
    };
};

export const linkTypeAlreadySelected = (option: string, links: CardProfileSocialLink[]) => (
    links.some(link => link.type === option)
);


export const getAvailableSelectorLinkOptions = (isCompanyCard: boolean, links: CardProfileSocialLink[]) => (
    options.filter(o => {
        return (!isCompanyCard || canEditLink(o as SocialLinks)) && !linkTypeAlreadySelected(o,links);
    })
);

export const canEditLink = (type: SocialLinks) => {
    if (type === LINKEDIN) return canEditCompanyLinkedin();
    if (type === X) return canEditX();
    if (type === INSTAGRAM) return canEditInstagram();
    if (type === TIKTOK) return canEditTikTok();
    if (type === FACEBOOK) return canEditFacebook();
    if (type === YOUTUBE) return canEditYoutube();
    if (type === SNAPCHAT) return canEditSnapchat();
    if (type === PINTEREST) return canEditPinterest();
    if (type === THREADS) return canEditThreads();
    if (type === REDDIT) return canEditReddit();

    return true;
};
