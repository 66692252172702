import { CardProfileContactUpdate } from '../../types/cardProfile';
import {
    APPOINTMENT_BOOKING,
    Contacts,
    EMAIL,
    HOME_EMAIL, HOME_PHONE,
    MOBILE_PHONE,
    OFFICE_PHONE, OTHER_WEBSITE,
    WEBSITE,
    WORK_EMAIL,
    WORK_PHONE
} from '../../types/contacts';
import {canEditAppointmentBooking, canEditEmail, canEditPhone, canEditWebsite} from '../../store/utils/company';


export const selectorOptions = [
    MOBILE_PHONE,
    EMAIL,
    WEBSITE,
    OTHER_WEBSITE,
    APPOINTMENT_BOOKING,
    WORK_PHONE,
    OFFICE_PHONE,
    HOME_PHONE,
    WORK_EMAIL,
    HOME_EMAIL,
];

export const MAX_NOOF_WEBSITES = 5;

export const contactTypeAlreadySelected = (option: string, contactInfos: CardProfileContactUpdate[]) => (
    contactInfos.some(contact => contact.type === option)
);

export const checkIfAllowedToAddContact = (
    option: string, contactInfos: CardProfileContactUpdate[], isMaxNoofWebsitesSelected: boolean, isMainWebsiteSelected: boolean
) => {
    if (isWebsiteContactType(option)) {
        return isMaxNoofWebsitesSelected || isMainWebsiteSelected;
    } else if (isOtherWebsiteContactType(option)) {
        return isMaxNoofWebsitesSelected || !isMainWebsiteSelected;
    }

    return contactTypeAlreadySelected(option, contactInfos);
};

export const getMenuItemStyles = (option: string, contactsInfos: CardProfileContactUpdate[], isMaxNoofWebsitesSelected: boolean, isMainWebsiteSelected: boolean) => {
    const isSelected = checkIfAllowedToAddContact(option, contactsInfos, isMaxNoofWebsitesSelected, isMainWebsiteSelected);
    const cursor = isSelected ? 'default' : '';

    return {
        fontWeight: isSelected ? 'bold' : 'normal',
        fontSize: '14px',
        cursor: cursor
    };
};

export const isPhoneContactType = (type: string) => {
    const t = type?.trim().toLowerCase();

    return t === WORK_PHONE.toLowerCase() ||
        t === OFFICE_PHONE.toLowerCase() ||
        t === MOBILE_PHONE.toLowerCase() ||
        t === HOME_PHONE.toLowerCase();
};

export const isEmailContactType = (type: string) => {
    const t = type?.trim().toLowerCase();

    return t === EMAIL.toLowerCase() ||
        t === WORK_EMAIL.toLowerCase() ||
        t === HOME_EMAIL.toLowerCase();
};

export const isWebsiteContactType = (type: string) => {
    const t = type?.trim().toLowerCase();

    return t === WEBSITE.toLowerCase();
};

export const isOtherWebsiteContactType = (type: string) => {
    const t = type?.trim().toLowerCase();

    return t === OTHER_WEBSITE.toLowerCase();
};

export const isAppointmentBookingType = (type: string) => {
    const t = type?.trim().toLowerCase();

    return t === APPOINTMENT_BOOKING.toLowerCase();
};


export const getAvailableSelectorOptions = (
    isCompanyCard: boolean, contactInfos: CardProfileContactUpdate[], isMaxNoofWebsitesSelected: boolean, isMainWebsiteSelected: boolean
) => (
    selectorOptions.filter(o => {
        const type = o === OTHER_WEBSITE ? WEBSITE : o;
        return (!isCompanyCard || canEditContact(type as Contacts)) && !checkIfAllowedToAddContact(o, contactInfos, isMaxNoofWebsitesSelected, isMainWebsiteSelected);
    })
);

export const canEditContact = (type: Contacts) => {
    if (type === WORK_PHONE || type === MOBILE_PHONE || type === OFFICE_PHONE || type === HOME_PHONE) {
        return canEditPhone();
    }

    if (type === WEBSITE) {
        return canEditWebsite();
    }

    if (type === WORK_EMAIL || type === HOME_EMAIL || type === EMAIL) {
        return canEditEmail();
    }

    if (type === APPOINTMENT_BOOKING) {
        return canEditAppointmentBooking();
    }

    return false;
};
