import {CardProfileAddress, CardProfileContact} from '../../../types/cardProfile';
import {getExternalUrl} from '../../../utils/common';
import {
    isAppointmentBookingType,
    isEmailContactType,
    isPhoneContactType,
    isWebsiteContactType
} from '../../../utils/contact/contact';

const getPhoneUrl = (url: string) => `tel:${url}`;

const getMailUrl = (url: string) => `mailto:${url}`;

export const getContactUrl = (contact: CardProfileContact) => {
    const type = contact.type?.trim().toLowerCase();
    const details = contact.details?.trim();

    if (isPhoneContactType(type)) return getPhoneUrl(details);
    if (isEmailContactType(type)) return getMailUrl(details);
    if (isWebsiteContactType(type) || isAppointmentBookingType(type)) return getExternalUrl(details);
    return '';
};

export const getLocationUrl = (address: CardProfileAddress) => {
    const googleMapsUrl = 'https://maps.google.com/maps?q=';
    return googleMapsUrl + address.address + ', ' + address.country + ', ' + address.city + ', ' + address.zip;
};