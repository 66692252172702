import React, {FC} from 'react';
import styles from './contacts.module.scss';
import {CardProfileAddress, CardProfileContact} from '../../../types/cardProfile';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as WebsiteIcon } from '../../../assets/icons/website.svg';
import { ReactComponent as AppointmentIcon } from '../../../assets/icons/appointment.svg';
import i18n from '../../../translations/i18n';
import {getContactUrl, getLocationUrl} from './contactsUtils';
import { notBlank } from '../../../utils/common';
import {
    isAppointmentBookingType,
    isEmailContactType,
    isPhoneContactType,
    isWebsiteContactType
} from '../../../utils/contact/contact';

interface ContactsProps {
    profileContacts: CardProfileContact[],
    profileAddresses?: CardProfileAddress,
    style: object,
    textStyle: object
    iconStyle: object 
}

const Contacts: FC<ContactsProps> = ({
    profileContacts, profileAddresses, style, iconStyle, textStyle
}) => {

    const getContactIcon = (contact: CardProfileContact) => {
        const type = contact.type;

        if (isPhoneContactType(type)) return <PhoneIcon style={iconStyle} />;
        if (isEmailContactType(type)) return <MailIcon style={iconStyle} />;
        if (isWebsiteContactType(type)) return <WebsiteIcon style={iconStyle} />;
        if (isAppointmentBookingType(type)) return <AppointmentIcon style={iconStyle} />;
        return <></>;
    };

    const getProfileAddress = (country: string | undefined, city: string | undefined) => {
        if (notBlank(country) && notBlank(city)) return `${country}, ${city}`;
        if (notBlank(country)) return country;
        if (notBlank(city)) return city;
        return '';
    };

    const getDisplayContactText = (contact: CardProfileContact) => {
        if (isAppointmentBookingType(contact.type)) return i18n.t('common.appointmentBooking');
        return contact.details;
    };

    return (
        <div className={styles.contactWrapper}>
            <div className={`${styles.text} ${styles.contactText}`} style={textStyle}>
                {i18n.t('common.contact')}
            </div>
            {profileContacts.filter(contact => notBlank(contact.type) && notBlank(contact.details)).map(contact => (
                <div className={styles.contact} key={contact.id}>
                    <a href={getContactUrl(contact)}>
                        <div className={`${styles.logoWrapper} ${styles.contactIconWrapper}`} style={style}>
                            {getContactIcon(contact)}
                        </div>
                    </a>
                    <div className={`${styles.text} ${styles.contactTextDetails}`} style={textStyle}>
                        {getDisplayContactText(contact)}
                    </div>
                </div>
            ))}
            {profileAddresses &&
                <div className={styles.contact} key={profileAddresses.id}>
                    {( profileAddresses.country || profileAddresses.city ) &&
                    <a href={getLocationUrl(profileAddresses)}>
                        <div className={`${styles.logoWrapper} ${styles.contactIconWrapper}`} style={style}>
                            <LocationIcon style={iconStyle}/>
                        </div>
                    </a>
                    }
                    {( profileAddresses.country || profileAddresses.city ) &&
                    <div className={`${styles.text} ${styles.contactTextDetails}`} style={textStyle}>
                        {getProfileAddress(profileAddresses.country, profileAddresses.city)}
                    </div>
                    }
                </div>
            }
        </div>
    );
};

export default Contacts;